// --- COLORS --- //
/* SCSS HEX */
$gunmetal: #2d3142ff;
$silver: #bfc0c0ff;
$white: #ffffffff;
$coral: #ef8354ff;
$paynes-gray: #4f5d75ff;

/* SCSS HSL */
$gunmetal: hsla(229, 19%, 22%, 1);
$silver: hsla(180, 1%, 75%, 1);
$white: hsla(0, 0%, 100%, 1);
$coral: hsla(18, 83%, 63%, 1);
$paynes-gray: hsla(218, 19%, 38%, 1);

/* SCSS RGB */
$gunmetal: rgba(45, 49, 66, 1);
$silver: rgba(191, 192, 192, 1);
$white: rgba(255, 255, 255, 1);
$coral: rgba(239, 131, 84, 1);
$paynes-gray: rgba(79, 93, 117, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-right: linear-gradient(90deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-bottom: linear-gradient(180deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-left: linear-gradient(270deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-top-right: linear-gradient(45deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-bottom-right: linear-gradient(135deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-top-left: linear-gradient(225deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-bottom-left: linear-gradient(315deg, #2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);
$gradient-radial: radial-gradient(#2d3142ff, #bfc0c0ff, #ffffffff, #ef8354ff, #4f5d75ff);


// --- FONTS --- //
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// font-family: 'Hind', sans-serif;
// font-family: 'Montserrat', sans-serif;

// --- MIXINS ---
// @mixin theme($theme: #34495e) {
@mixin theme($theme: #3C6E71) {
  // background: $theme;
  // background: rgb(25, 36, 47);
  // background: linear-gradient(0deg, rgba(25, 36, 47, 1) 0%, rgba(52, 73, 94, 1) 100%);
  box-shadow: 0 0 1px rgba($theme, .25);
  color: #fff;
}


// --- BASE ---
html,
body {
  @include theme;
  // background: rgb(25, 36, 47);
  // background: linear-gradient(0deg, rgba(25, 36, 47, 1) 0%, rgba(52, 73, 94, 1) 100%);
  background: $gunmetal;
  font-family: 'Hind', sans-serif;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;

}

body {
  text-align: center;
}

h1, h2, h3, h4, h5, h6, .title {
  font-family: 'Montserrat', sans-serif;
}

// --- BOOTSTRAP OVERWRITES --- //
.grid { 
  display: grid; 
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
.card {
  background: #BFC0C0;
  color: #34495e;
}
.g-col-6

// --- NAVBAR ---
.navbar-nav {
  .nav-item {
    a {
      text-decoration: none;
      // color: inherit;
    }
  }
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// --- GOOGLE BUTTON ---
/* GOOGLE BUTTOn */
.login-with-google-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 1rem 1rem .8rem 2.5rem;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 1.2rem;
  font-weight: 500;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  //   Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: .8rem 1.05rem;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

// --- GREETING ANIMATION ---
// _greeting.scss

// --- HOME --- 
.home-page {
  svg {
    margin-top: 12rem;
    width: 20%;
  }
}



// --- QUORUM ---
.quorum-stats {
  text-align: start;
  * {
    display: flex;
    // height: 100%;
  }
  .card-body {
    .col-md-4 {
      flex-direction: column;
    }
    // align-items: flex-start;
    justify-content: space-around;
    .card-text {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      span {
        align-items: baseline;
        justify-content: baseline;
      }
    }
    .card-status .badge{
      min-width: 30%;
      max-width: 100%;
    }
  }
  .status-icon {
    .fa-circle-check{ color: rgb(25, 135, 84); }
    .fa-circle-xmark{ color: rgb(220, 53, 69); }
  }
  #rsvp-btn{
    background-color: rgb(22, 160, 133);
    border: rgb(22, 160, 133);
    transition: all ease-in-out 200ms;
    &:hover{
      transform: scale(1.02);
      font-weight: 600;
    }
  }
}
.quorum-users {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & .current-user {
    transition: all ease-in-out 200ms;
    &:hover {
      transform: scale(1.02);
      font-weight: 600;

    }
    .edit-msg{
      padding: .5rem 1rem;
      transition: all ease-in-out 200ms;
      &:hover{
      cursor: pointer;
      // border: black 1px solid;
      background: rgb(22, 160, 133);
      color: white;
      border-radius: 5px;
      }
    }
  }
  .avatar-container {
    // width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    // background: #c6c5c4;
    border-radius: 10px;
    .avatar-card {
      // align-items: center;
      flex-basis: 30%;
      margin: .5rem;
      color: #34495e;
      background: rgba(240, 240, 240, 0.8);
      .card-footer {
        // button {
        //   color: #fff;
        //   background: #34495e;
        // }
      }
      .avatar-img {
        width: 50%;
        min-width: 5rem;
      }
    }
    sl-avatar {
      --size: 6rem;
      // flex-basis: 25%;
      margin: 1rem;      
    }
  }
  // https: //www.gradient-animator.com/
  .status-btn-in {
    width: 80%;
    font-weight: 600;
    background: linear-gradient(78deg, #80ff72, #74f2ce, #80ff72);
    background-size: 600% 600%;

    -webkit-animation: StatusInAnimation 10s ease infinite;
    -moz-animation: StatusInAnimation 10s ease infinite;
    animation: StatusInAnimation 10s ease infinite;
  }

  @-webkit-keyframes StatusInAnimation {
      0%{background-position:99% 0%}
      50%{background-position:2% 100%}
      100%{background-position:99% 0%}
  }
  @-moz-keyframes StatusInAnimation {
      0%{background-position:99% 0%}
      50%{background-position:2% 100%}
      100%{background-position:99% 0%}
  }
  @keyframes StatusInAnimation {
      0%{background-position:99% 0%}
      50%{background-position:2% 100%}
      100%{background-position:99% 0%}
  }

  
  .status-btn-out {
    width: 80%;
    font-weight: 600;
    background: linear-gradient(78deg,#f95353,
      #fca3a3,
      #fb6464);
    background-size: 1000% 1000%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
  }

  @-webkit-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  @-moz-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  @keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  .status-btn-pending {
    width: 80%;
    font-weight: 600;
    background: linear-gradient(78deg, #f6de69,
      #ebf472,
      #f6de69);
    background-size: 1000% 1000%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
  }

  @-webkit-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  @-moz-keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  @keyframes AnimationName {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
}

.set-status-btn {
  color: #eee;
  // background: linear-gradient(78deg, #4f6a85, #538eaa, #47848e);
  // background-size: 600% 600%;

  -webkit-animation: AnimationName 9s ease infinite;
  -moz-animation: AnimationName 9s ease infinite;
  animation: AnimationName 9s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}

.modal-content {
  color: #34495e;
  .modal-title {
    padding-left: 1.5rem;
  }
  .modal-body {
    padding: 3rem;

  }
}

#user-collection-cmpt {
  display: flex;
  justify-content: start;
  text-align: start;
}

// --- PROGRESS BAR --- //
#progress-bar{
  padding: 0 4rem;
}

.progress-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-left:6px solid rgb(22, 160, 133);
  .progress-step {
    align-items: center;
    justify-content: flex-start;
  }
  .step-line{
    width: calc(100% - 30px); // 11% * 6 => 66% => col-8 
    height: 6px;
    background: white;
    border-top: 2px solid rgb(22, 160, 133);
    border-bottom: 2px solid rgb(22, 160, 133);
    &.filled{
      background: rgb(22, 160, 133);
      z-index: 2;
    }
  }
  .step-bubble {
    display: flex;
    background: white;
    color: inherit;
    font-size: large;
    width: 30px;
    height: 30px;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid rgb(22, 160, 133);
    &.filled {
      background-color: rgb(22, 160, 133);
      color: white;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 600;
    }
  }
  .step-glow .step-bubble.filled{
    position: relative;
    border-radius: 100px;
    z-index: 1;
    animation: glow 3s infinite alternate;
  }
  // #progress-msg {
  //   position: absolute;
  //   bottom: 10px;
  //   right: 25%;
  // }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #edf4ae;
  }

  to {
    box-shadow: 0 0 10px 10px #edf4ae;
  }
}

// --- AUDIO PLAYER --- //
.audio-player {
  max-width: 350px;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: var(--white);
}

.artwork {
  border-radius: 12px;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
  height: 200px;
  width: 200px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  font-weight: 700;
  margin-bottom: 4px;
}

.artist {
  font-weight: 300;
  margin-top: 0;
}

.progress {
  margin: 1rem auto;
}

.audio-controls {
  display: flex;
  justify-content: center;
  width: 75%;
  margin: 0 auto 15px;
  button {
    border: none;
    background: transparent;
  }
}

// .audio-controls .prev svg,
// .audio-controls .next svg {
//   width: 35px;
//   height: 35px;
// }

.audio-controls .play i,
.audio-controls .pause i {
  color: white;
  font-size: 4rem;

}

// --- COUNTDOWN --- //
#countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  .time-unit {
    min-width: 70px;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    border-radius: 12px;
  }
  .unit-title {
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  .unit {
    font-size: 1.5rem;
    font-weight: 600;
  }

}

@media (max-width: 991px) {
  // boostrap medium breakpoint
  .progress-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
// --- MOBILE ONLY --- //
@media (max-width: 768px) { 
  // bootstrap small breakpoint
  // --- NABVAR ---
  .nav-li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .progress-wrapper{
    flex-direction: column;
    #progress-bar{
      padding: 1rem 0;
    }
    
  }

}
@media (max-width: 576px) {
  .content {
    padding: 1rem;
  }
}
