.greeting-wrapper {
  width: 100%;
  height: 40%;
  position: fixed;
}

.greeting {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 160px;
  overflow: hidden;

  font-size: 35px;
  line-height: 40px;
  color: #ecf0f1;

  &-container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    padding: 0 40px;

    &:before {
      content: '[';
      left: 0;
    }

    &:after {
      content: ']';
      position: absolute;
      right: 0;
    }

    &:after,
    &:before {
      position: absolute;
      top: 0;

      color: #16a085;
      font-size: 42px;
      line-height: 40px;

      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &-text {
      display: inline;
      float: left;
      margin: 0;
    }

    &-list {
      margin-top: 0;
      padding-left: 110px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &-item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -16.6%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -33.3%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -50%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -66.6%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -83.3%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -16.6%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -33.3%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -50%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -66.6%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -83.3%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -16.6%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -33.3%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -50%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -66.6%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -83.3%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -16.6%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -33.3%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -50%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -66.6%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -83.3%, 0);
  }
}

// 6 is the number of animation.
// Here, there are 4 lines :

// 1 to 2
// 2 to 3
// 3 to 4
// 4 to 3
// 3 to 2
// 2 to 1

// 6x + 6y = 100 (100% duration)

// HERE : 
// y = 4 -> Animation between two lines
// x = 12.66 -> Time spent on a line

// You can define a value and calculate the other if you want change speed or the number of lines