// --- Imports ---
@use 'styles/reset';
@use 'styles/base';
@use 'styles/greeting';
@use 'styles/ratings';



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
