// AG-Grid styles
// https://www.ag-grid.com/react-data-grid/global-style-customisation-variables

.ratings-table-wrapper {
  width: 100%;
  height: 100%;
}

#ratings-table {
  width: 100%;
  height: 600px;
  text-align: start;
}